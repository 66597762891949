import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import doc_list_1 from '../../../assets/doc_list_1.png'
import doc_list_2 from '../../../assets/doc_list_2.png'
import doc_list_3 from '../../../assets/doc_list_3.png'
import doc1 from '../../../assets/doc1.png'
import docsub1 from '../../../assets/docsub1.png'
import docsub2 from '../../../assets/about_img.png'
import { BsArrowRight } from "react-icons/bs";


const DocAbout = () => {
    return (
        <>
            <div className="doc_about mt-5 pt-5">

                <div className="doc_detail_upper">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <div className="doc_details_head">
                                    <h1>Meet Our Expert Physicians </h1>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="doc_head_brief">
                                    <p>Embark on a journey of well-being with our accomplished team of specialized doctors. From
                                        Maxillofacial Aesthetics to Pulmonology, Diabetes Care to Aesthetic Medicine, our experts
                                        are devoted to enhancing your health and beauty, ensuring comprehensive care and
                                        transformative results.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container>
                    <div className="doc_list">
                        <Row>
                            <Col md={4}>
                                <div className="doc_list_card doc1">
                                    <img src={doc_list_1} alt="" />
                                    <div className="doc_card_data">
                                        <h5>Dr. Gaurav Pandey <span>(MD Pulmonary Medicine)</span></h5>
                                        <p>Pulmonologist & Diabetologist</p>
                                        <div className="doc_btn pt-1">
                                            <a href="/doctor"><Button>Read More</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="doc_list_card doc2">
                                    <img src={doc_list_2} alt="" />
                                    <div className="doc_card_data">
                                        <h5>Dr. Shweta Mishra <span>(MDS, FAM, DAM Canada)</span></h5>
                                        <p>Maxillofacial Aesthetic Surgeon</p>
                                        <div className="doc_btn pt-1">
                                            <a href="/doctor"><Button>Read More</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="doc_list_card doc3">
                                    <img src={doc_list_3} alt="" />
                                    <div className="doc_card_data">
                                        <h5>Dr. Shubham Pandey <span>(MBBS, FAM)</span></h5>
                                        <p>Aesthetic Physician</p>
                                        <div className="doc_btn pt-1">
                                            <a href="/doctor"><Button>Read More</Button></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="sharva_about mt-5">
                        <Row>
                            <Col md={6}>
                                <div className="docimg">
                                    <img src={doc1} alt="" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='Button_healthy1 mt-5 mb-4 pt-5'>
                                    <span className='lets'>About Us</span>
                                </div>
                                <div className="docright">
                                    <h1 className='pt-2'>Welcome to Sharva Clinique: Where Excellence Meets Care</h1>
                                    <p className='py-3'>Sharva Clinique stands as an unparalleled, exceptional state-of-the-art destination for cosmetic, pulmonary, and diabetes care.</p>

                                    <div className="docsub">
                                        <Row>
                                            <Col md={10} xs={10}>
                                                <div className="subleft">
                                                    <span>Your Wellness, Our Promise: Nurturing with Compassion.</span>
                                                    <p className='pt-2'>Situated in the heart of New Delhi, we take pride in offering cutting-edge treatments that incorporate the latest global advancements and superior technologies. Our committed team of experts ensures that your well-being is our utmost priority, and we are dedicated to delivering care that is as unique as you are.</p>
                                                </div>
                                            </Col>
                                            <Col md={2} xs={2}>
                                                <div className="subright">
                                                    <img src={docsub1} alt="" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={5}>
                                <div className="docparts my-5">
                                    <Row>
                                        <Col md={5}>
                                            <div className="docpoints">
                                                <ul>
                                                    <li><BsArrowRight className='arrow_right' /> Expert Care</li>
                                                    <li><BsArrowRight className='arrow_right' /> Personalized Attention</li>
                                                    <li><BsArrowRight className='arrow_right' /> Cutting-edge Treatments</li>
                                                    <li><BsArrowRight className='arrow_right' /> Compassionate Approach</li>
                                                    <li><BsArrowRight className='arrow_right' /> Transformative Results</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <div className="docpart1">
                                                <p>"Experience the difference of our patient-centred approach, where your health and happiness are at the core of everything we do. "</p>
                                                {/* <div className="docsign mt-3">
                                                <img src={docsign} alt="" />
                                                <div className="doc_name pt-3">
                                                    <p>Dr. Jack Lane</p>
                                                    <small>chyrone founder</small>
                                                </div>
                                            </div> */}
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={6}>
                                <div className="docsub1 mt-5">
                                    <Row>
                                        <Col md={2} xs={2}>
                                            <div className="subright">
                                                <img src={docsub2} alt="" />
                                            </div>
                                        </Col>
                                        <Col md={10} xs={10}>
                                            <div className="subleft1">
                                                <span>Discuss Who We Are </span>
                                                <p className='pt-2'>Sharva Clinique is a super-specialitycentre devoted to Facial Aesthetics, Pulmonary Diseases, and Diabetes. Our vision is to provide the highest quality treatments, ensuring your journey towards health, beauty, and well-being is exceptional.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="doc_btn pt-4">
                                        <a href="/about"><Button>Learn More</Button></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default DocAbout;
