import React from 'react';
import AboutBanner from './aboutbanner/aboutBanner';
import Otherheader from  '../Header/Otherheader';
import Doctorabout from './doctor_about/Doctorabout';
import Aboutdesc from './about_desc/Aboutdesc';
import Testimonial from '../home/testimonial/testimonial';
import Whatsapp from '../Whatsapp/Whatsapp';


const About = () => {
    return (
        <>
            <Otherheader/>
            <AboutBanner />
            <Aboutdesc/>
            <Doctorabout/>
            <Testimonial/>
            <Whatsapp/>
        </>
    );
}

export default About;
