import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import about_banner from '../../../assets/about_banner.png'
import { LiaGreaterThanSolid } from "react-icons/lia";
import '../about.css'

const AboutBanner = () => {
    return (
        <>
            <div className='aboutbanner'>
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="banner_left1">
                                <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon'/> About</p>
                                <div className="banner_head pt-4">
                                    <h1>Sharva Clinique</h1>
                                </div>
                                <div className="banner_p pt-4">
                                    <p>At Sharva Clinique, we stand as an unparalleled state-of-the-art center, seamlessly merging the realms of cosmetic, pulmonary, and diabetes care. Nestled in the heart of New Delhi, our commitment to modern and advanced world-class treatments, anchored in cutting-edge technologies and the latest global clinical insights, ensures your health journey is nothing short of exceptional.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="banner_img mt-5">
                                <img src={about_banner} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AboutBanner;
