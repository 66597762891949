import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import desc_img from '../../../assets/abt_desc.png';
import desc_img1 from '../../../assets/abt_desc1.png';
import desc_img2 from '../../../assets/abt_desc2.png';

const Aboutdesc = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
    };

    return (
        <>
            <div className="aboutDesc my-5 pt-4">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="aboutdesc_left">
                                <h2>Maxillofacial Aesthetic Surgeon</h2>
                                <p>Dr. Shweta Mishra, our skilled Maxillofacial Aesthetic Surgeon, dedicated to enhancing your natural allure. With precision procedures, from facelifts to lip enhancements, she crafts transformations that reflect your unique elegance. Discover rejuvenation that captures the essence of your beauty.</p>

                                <div className="abt_count">
                                    <Row>
                                        <Col md={6}>
                                            <div className="count_card1">
                                                <h4>500+</h4>
                                                <p>Satisfied Clients</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="count_card2">
                                                <h4>10+</h4>
                                                <p>Experience</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <div className="count_card3">
                                                <h4>5+</h4>
                                                <p>Professional Staff</p>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="count_card4">
                                                <h4>1</h4>
                                                <p>Branch Clinic</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="about_desc_img">
                                <img src={desc_img} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <div className="about_desc_img mt-5">
                                <img src={desc_img1} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="aboutdesc_left mt-5">
                                <h2 className='mt-5'>Pulmonologist</h2>
                                <p className='mt-4'>Meet our esteemed Pulmonologist, a healthcare professional dedicated to optimizing your respiratory health. With a profound understanding of lung conditions, our Pulmonologist offers specialized care for asthma, COPD, sleep disorders, and more. Through evidence-based practices and cutting-edge treatments, we aim to empower you to breathe freely and live fully. <br /><br />
                                    Our Pulmonologist's expertise extends beyond diagnostics, focusing on personalized treatment plans tailored to your unique needs. Whether you seek asthma management, COPD care, or solutions for sleep disturbances, our Pulmonologist strives for optimal well-being. With a compassionate approach and a commitment to excellence, our Pulmonologist stands as your partner in the journey to robust respiratory health. <br /><br /> Trust in their experience to guide you toward a life where each breath is a testament to vitality and wellness.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={6}>
                            <div className="aboutdesc_left mt-5">
                                <h2 className='mt-5'>Aesthetic Physician</h2>
                                <p className='mt-4'>Introducing our Aesthetic Physician, a dedicated professional focused on enhancing your natural charm. With expertise in non-invasive treatments, our Aesthetic Physician offers solutions for aging or damaged skin, including Botox, dermal fillers, and aesthetic lasers. <br/><br/>
                                    Embrace personalized care as our Aesthetic Physician tailors treatments to your unique needs, addressing wrinkles, sagging, pigmentation, and more. From chemical peels to microblading, each procedure aims to reveal your inner radiance. <br/><br/>
                                    With a commitment to your well-being, our Aesthetic Physician delivers treatments that restore confidence and rejuvenate your appearance. Step into a world of transformative beauty where every procedure reflects your individual allure. Trust in our Aesthetic Physician's skill to guide you toward a more vibrant and youthful version of yourself.
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="about_desc_img mt-5">
                                <img src={desc_img2} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Aboutdesc;


