import React, { useEffect } from 'react';
import Otherheader from '../Header/Otherheader';
import { Container, Row, Col } from 'react-bootstrap';
import { LiaGreaterThanSolid } from "react-icons/lia";
import { useParams } from 'react-router-dom';
import service1 from '../../assets/service1.png';
import service2 from '../../assets/service2.png';
import service3 from '../../assets/service3.png';
import service4 from '../../assets/service4.png';
import service5 from '../../assets/service5.png';
import service6 from '../../assets/service6.png';
import service7 from '../../assets/service7.png';
import service8 from '../../assets/service8.png';
import service9 from '../../assets/service9.png';
import service10 from '../../assets/service10.png';
import service11 from '../../assets/service11.png';
import service12 from '../../assets/service12.png';
import service13 from '../../assets/service13.png';
import service14 from '../../assets/service14.png';
import service15 from '../../assets/service15.png';
import service16 from '../../assets/service16.png';
import service17 from '../../assets/service17.png';
import service18 from '../../assets/service18.png';
import service19 from '../../assets/service19.png';
import service20 from '../../assets/service20.png';
import service21 from '../../assets/service21.png';
import service39 from '../../assets/service39.png';
import service40 from '../../assets/service40.png';
import service41 from '../../assets/service41.png';
import service42 from '../../assets/service42.png';
import service43 from '../../assets/service43.png';
import service44 from '../../assets/service44.png';
import service45 from '../../assets/service45.png';
import service46 from '../../assets/service46.png';
import service47 from '../../assets/service47.png';
import service48 from '../../assets/service48.png';
import service49 from '../../assets/service49.png';
import service50 from '../../assets/service50.png';
import service51 from '../../assets/service51.png';
import './Services.css';
import Whatsapp from '../Whatsapp/Whatsapp';

const Services = () => {
    const { section } = useParams();

    useEffect(() => {
        const targetSection = document.getElementById(section);
        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, [section]);

    return (
        <>
            <Otherheader />
            <div className="service_banner">
                <Container>
                    <div className="appoint_left">
                        <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Services </p>
                    </div>

                    <div className="gallery_head text-white">
                        <h1 className='pt-5'>Services at Sharva <br /> Clinique </h1>
                        <p className='pt-2'>Explore our range of expert services, each tailored to your <br /> unique needs and delivered by our skilled team of professionals <br />  at Sharva Clinique. Your journey towards enhanced health and beauty starts here.</p>
                    </div>
                </Container>
            </div>

            <div className="service_content my-5">
                <div className="service_head text-center mb-5">
                    <h2>Our <span>Services</span></h2>
                </div>

                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="service_name" id='AWI'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Anti Wrinkle Injection</h5>
                                    <p>Experience a revitalized appearance with the <strong>best anti-wrinkle treatment in Delhi</strong>  from our expert Maxillofacial Aesthetic Surgeon. Safely and skilfully administered, these injections effectively target and soften fine lines and wrinkles, restoring a youthful radiance to your face. Our surgeon's precise techniques ensure natural-looking results, enhancing your features without sacrificing expression. Unveil smoother, more vibrant skin as we employ the latest advancements in facial aesthetics. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to help you achieve a rejuvenated and confident you, with anti-wrinkle injections tailored to your unique needs. Rediscover your beauty and embrace the ageless allure that defines you.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service1} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service2} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='dermal'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Dermal Fillers</h5>
                                    <p>Revitalize your beauty with our distinguished Maxillofacial Aesthetic Surgeon's dermal filler treatments. Skillfully administered, these fillers enhance facial contours, smooth wrinkles, and restore youthful volume. Our surgeon's expertise ensures a natural, harmonious result, accentuating your features while maintaining your unique expressions. Using cutting-edge techniques, we offer personalized dermal filler solutions, recognized as the <strong>best dermal fillers treatment in Delhi,</strong> that rejuvenate and refresh, revealing a more radiant you. Trust our experienced Maxillofacial Aesthetic Surgeon to artistically sculpt and revitalize, helping you achieve your aesthetic goals with precision and care. Embrace the transformative power of dermal fillers and embark on a journey towards timeless elegance.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='boosters'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Injectable Skin Boosters </h5>
                                    <p>Elevate your skin's vitality with the expertise of our distinguished Maxillofacial Aesthetic Surgeon through injectable skin boosters. Meticulously administered, these treatments invigorate your skin, promoting hydration, elasticity, and a radiant complexion. Our surgeon's skill ensures a natural, rejuvenated result that enhances your skin's texture and appearance. Through advanced techniques, we provide tailored solutions that address your unique needs, revitalizing your skin's health and beauty. Recognized as the  <strong>skin boosters treatment in Delhi,</strong>  we offer advanced techniques and tailored solutions that address your unique needs, revitalizing your skin's health and beauty. Rely on our experienced Maxillofacial Aesthetic Surgeon to artfully enhance and nourish your skin, guiding you towards a revitalized and confident version of yourself
                                        Experience the transformative effects of injectable skin boosters and embrace a luminous, age-defying glow.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service3} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service21} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='hydrofacial'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Hydro facial</h5>
                                    <p>Elevate your skin's vitality with the expertise of our distinguished Maxillofacial Aesthetic Surgeon through injectable skin boosters. Meticulously administered, these treatments invigorate your skin, promoting hydration, elasticity, and a radiant complexion. Our surgeon's skill ensures a natural, rejuvenated result that enhances your skin's texture and appearance.In addition to injectable boosters, we also offer the <strong>HydraFacial treatment in Delhi</strong>  to further rejuvenate and hydrate your skin. Through advanced techniques, we provide tailored solutions that address your unique needs, revitalizing your skin's health and beauty.
                                        Rely on our experienced Maxillofacial Aesthetic Surgeon to artfully enhance and nourish your skin, guiding you towards a revitalized and confident version of yourself. Experience the transformative effects of injectable skin boosters and embrace a luminous, age-defying glow.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='peels'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Chemical Peels </h5>
                                    <p>Unveil a radiant complexion with our Maxillofacial Aesthetic Surgeon's transformative chemical peels. Carefully administered, these peels exfoliate and renew your skin, addressing imperfections, fine lines, and uneven tone. Recognized as the <strong>Best Chemical Peeling Treatment in Delhi,</strong>  chemical peels are meticulously applied to exfoliate and revitalize your skin, treating flaws, fine wrinkles, and uneven tone. Our surgeon's expertise ensures a tailored approach, selecting the ideal peel to suit your unique skin needs and goals.Through advanced techniques, we offer a rejuvenating solution that reveals smoother, more youthful skin. Trust in our experienced Maxillofacial Aesthetic Surgeon to guide you towards a revitalized appearance, where the art of chemical peels unveils your inner radiance. Embrace the journey to healthier, more vibrant skin and experience the confidence that comes with it.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service4} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service5} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='meso'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Mesotherapy</h5>
                                    <p>Discover the revitalizing benefits of Mesotherapy, expertly administered by our Maxillofacial Aesthetic Surgeon. This innovative treatment involves precise micro-injections of customized serums, targeting specific skin concerns such as hydration, firmness, and rejuvenation. Our surgeon's skill ensures a tailored approach, addressing your unique needs and enhancing your skin's texture and appearance. Through advanced techniques, we offer a rejuvenating solution that stimulates collagen production and promotes a radiant complexion. Place your trust in our experienced Maxillofacial Aesthetic Surgeon to guide you on a transformative journey, where Mesotherapy rejuvenates and revitalizes, revealing your natural beauty and confidence. Trust our experienced Maxillofacial Aesthetic Surgeon to guide you on a transformative journey with  <strong>Mesotherapy Treatments in Delhi,</strong> where each session revitalizes and refreshes, unveiling your natural beauty and boosting your confidence. Embrace the power of Mesotherapy and embark on a path towards radiant, youthful skin.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='resurfacing'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Skin Resurfacing Procedure</h5>
                                    <p>Experience a renewed complexion with the <strong>best acne and wrinkles treatment in Delhi, </strong>  through our cutting-edge Skin Resurfacing Treatment led by our accomplished Maxillofacial Aesthetic Surgeon.  This advanced technique delicately removes damaged outer layers, unveiling fresh skin beneath. This advanced technique delicately removes damaged outer layers, unveiling fresh skin beneath. Our surgeon's expertise ensures a personalized approach, tailoring the procedure to your skin's unique needs, and targeting concerns like acne scars, wrinkles, and uneven texture. Through precise methods, we offer a transformative solution that promotes collagen production and reveals smoother, more youthful skin. Entrust our experienced Maxillofacial Aesthetic Surgeon to guide you toward a revitalized appearance, where the art of skin resurfacing renews your confidence. Embrace the journey to radiant skin and experience a renewed sense of self-assurance.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service6} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service7} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='laser'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Laser Hair Reduction</h5>
                                    <p>Embrace the freedom of smooth, hair-free skin with our advanced <strong>Laser Hair Reduction treatments,</strong>  recognized as the  <strong>Best Laser Hair Removal in Delhi</strong> and overseen by our skilled Maxillofacial Aesthetic Surgeon. This state-of-the-art procedure utilizes targeted laser energy to safely and effectively reduce unwanted hair. Our surgeon's expertise ensures a personalized approach, tailoring the treatment to your unique skin type and hair colour. Through precise techniques, we offer a lasting solution that minimizes hair growth and delivers long-lasting results. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a transformative journey, where Laser Hair Reduction frees you from the hassles of constant grooming. Embrace the confidence of effortlessly smooth skin and revel in a new level of self-assured beauty.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='regrowth'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Hair Regrowth Treatment Therapy </h5>
                                    <p>Experience a revitalized mane through our advanced <strong>Hair Regrowth Treatment in Delhi,</strong>  led by our esteemed Maxillofacial Aesthetic Surgeon. This specialized procedure employs innovative techniques to stimulate dormant hair follicles and encourage natural hair regrowth.
                                        Our surgeon's expertise ensures a customized approach, tailoring the treatment to your specific hair loss concerns and needs. Through precise methods, we offer a transformative solution that promotes thicker, healthier hair. Place your trust in our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Hair Regrowth Treatment Therapy helps you reclaim a full and vibrant head of hair. Embrace the potential of regrowth and experience a renewed sense of self-assurance and allure.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service8} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service9} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='micro'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Microdermabrasion</h5>
                                    <p>Reveal your skin's radiance with <strong>Microdermabrasion Treatment in Delhi,</strong>  a rejuvenating procedure meticulously administered by our skilled Maxillofacial Aesthetic Surgeon. This non-invasive treatment gently exfoliates the skin, removing dead cells and promoting a smoother, more youthful complexion. Our surgeon's expertise ensures a personalized approach, tailoring the treatment to your unique skin needs and goals. Through precise techniques, we offer a transformative solution that addresses fine lines, sun damage, and uneven texture. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to revitalized skin, where Microdermabrasion unveils your natural beauty and confidence. Embrace the potential of radiant skin and experience a renewed sense of self-assured allure.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='makeup'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Semi-Permanent Makeup</h5>
                                    <p>Enhance your natural beauty with Semi-Permanent Makeup, an artful technique masterfully executed by our esteemed Maxillofacial Aesthetic Surgeon. This specialized procedure involves carefully applying pigments to mimic makeup, creating lasting results for eyebrows, eyeliner, and lip colour.Our surgeon's skill ensures a tailored approach, accentuating your features and achieving the desired look. Through precise methods, we offer a transformative solution that saves time and enhances your daily routine. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to effortless beauty, where Semi-Permanent Makeup empowers you with confidence and a flawless appearance. Embrace the potential of timeless elegance and experience the convenience of naturally enhanced features.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service10} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service11} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='facelift'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Face Lift </h5>
                                    <p>Experience the transformative power of Facelift Surgery in Delhi under the expert guidance of our accomplished Maxillofacial Aesthetic Surgeon. This rejuvenating procedure addresses sagging skin, wrinkles, and jowls, restoring a more youthful and lifted appearance. Our surgeon's skill ensures a personalized approach, tailoring the facelift to your unique facial structure and aesthetic goals. Through meticulous techniques, we offer a dramatic solution that tightens underlying tissues and repositions skin, delivering natural-looking, long-lasting results. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where a facelift helps you reclaim a refreshed and rejuvenated version of yourself. Embrace the potential of a youthful transformation and experience the empowerment of a revitalized appearance.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='eyelid'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Eyelid Surgery </h5>
                                    <p>Rediscover a youthful gaze with Eyelid Surgery, skillfully performed by our accomplished Maxillofacial Aesthetic Surgeon. This rejuvenating procedure targets sagging eyelid skin, puffiness, and wrinkles, resulting in a refreshed and revitalized look. Our surgeon's expertise ensures a personalized approach, tailoring the surgery to your unique eye contour and desired outcomes. Through precise techniques, we offer a transformative solution that tightens and rejuvenates the delicate eyelid area, delivering natural-looking, long-lasting results. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Eyelid Surgery helps you reclaim a more vibrant and youthful appearance. Embrace the potential of a brighter outlook and experience the empowerment of revitalized eyes.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service12} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service13} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='necklift'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Neck Lift</h5>
                                    <p>Elevate your profile with a Neck lift, meticulously performed by our skilled Maxillofacial Aesthetic Surgeon. We provide the best neck lifting surgery in delhi, this rejuvenating procedure targets sagging skin, excess fat, and muscle laxity in the neck area, restoring a more youthful and defined contour. Our surgeon's expertise ensures a tailored approach, adapting the Necklift to your unique anatomy and aesthetic goals. Through precise techniques, we offer a transformative solution that tightens underlying tissues and removes excess skin, resulting in a natural and harmonious outcome. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where a Necklift helps you achieve a more refined and elegant neckline. Embrace the potential of a graceful transformation and experience the empowerment of a rejuvenated appearance.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='browlift'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Brow Lift</h5>
                                    <p>Rejuvenate your expression with a Brow Lift, expertly performed by our accomplished Maxillofacial Aesthetic Surgeon. This transformative procedure targets sagging brows, forehead wrinkles, and furrows, creating a refreshed and more youthful appearance. Our surgeon's expertise ensures a personalized approach, tailoring the Brow Lift to your unique facial structure and desired outcomes. Through meticulous techniques, we offer a transformative solution that lifts and repositions the brow area, delivering natural-looking, long-lasting results. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where a Brow Lift helps you achieve a brighter and more rejuvenated look. Embrace the potential of a revitalized appearance and experience the empowerment of a more lifted and youthful brow. </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service14} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service15} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='chin'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Chin Surgery </h5>
                                    <p>Transform your facial balance and contour with <strong>Chin Implant Surgery in Delhi, </strong> artfully executed by our accomplished Maxillofacial Aesthetic Surgeon. This procedure enhances chin projection, reshapes the jawline, and harmonizes facial proportions for a more refined appearance. Our surgeon's expertise ensures a personalized approach, tailoring the surgery to your unique facial features and aesthetic goals. Through precise techniques, we offer a transformative solution that creates a stronger and more defined chin profile, resulting in natural-looking, long-lasting results. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Chin Surgery helps you achieve a balanced and harmonious facial silhouette. Embrace the potential of a more sculpted look and experience the empowerment of a redefined and confident appearance.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='lip'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Lip Surgery </h5>
                                    <p>Enhance your lips with precision and artistry through <strong>Lip Surgery in Delhi, </strong> skillfully performed by our accomplished Maxillofacial Aesthetic Surgeon. This procedure offers a range of options, from lip augmentation to reshaping, to create fuller, balanced, and naturally appealing lips. Our surgeon's expertise ensures a personalized approach, tailoring the surgery to your unique lip structure and desired outcomes.  Through meticulous techniques, we offer a transformative solution that enhances lip volume, shape, and symmetry, resulting in natural-looking, long-lasting results. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Lip Surgery helps you achieve the luscious and confident lips you desire. Embrace the potential of a more alluring smile and experience the empowerment of beautifully enhanced lips.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service20} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service16} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='transplant'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Hair Transplant</h5>
                                    <p>Rediscover a fuller head of hair with Hair Transplant at the <strong>Best Hair Transplant Clinic in Delhi,</strong>  expertly performed by our accomplished Maxillofacial Aesthetic Surgeon. This transformative procedure addresses hair loss and thinning by relocating healthy hair follicles from donor areas to areas of concern, achieving natural-looking and long-lasting results. Our surgeon's expertise ensures a personalized approach, tailoring the Hair Transplant to your unique hair pattern and desired outcomes. Through meticulous techniques, we offer a transformative solution that restores hair density, improves hairline, and boosts self-confidence. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Hair Transplant helps you reclaim a fuller and more youthful hairline. Embrace the potential of a revitalized appearance and experience the empowerment of a hair restoration transformation.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='dimple'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Dimple Creation</h5>
                                    <p>Accentuate your smile with Dimple Creation Surgery in Delhi, an artistic procedure masterfully executed by our accomplished Maxillofacial Aesthetic Surgeon. This innovative technique involves creating subtle dimples on your cheeks, adding charm and character to your expression.
                                        Our surgeon's expertise ensures a personalized approach, tailoring the Dimple Creation to your unique facial features and desired results. Through meticulous techniques, we offer a transformative solution that enhances your smile and adds a touch of youthful playfulness. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Dimple Creation helps you achieve a more captivating and engaging appearance. Embrace the potential of a smile transformation and experience the empowerment of uniquely charming dimples.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service17} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service18} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='earlube'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Earlobe Repair</h5>
                                    <p>Restore the natural beauty of your ears with Earlobe Repair, a precise procedure skilfully performed by our accomplished Maxillofacial Aesthetic Surgeon. This transformative technique addresses stretched or torn earlobes due to earrings, accidents, or ageing. Our surgeon's expertise ensures a personalized approach, tailoring the Earlobe Repair to your specific needs and desired outcomes. Through meticulous techniques, we offer a transformative solution that restores the integrity of your earlobes, allowing you to confidently wear earrings again. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Earlobe Repair helps you regain the elegance and symmetry of your earlobes. Embrace the potential of a rejuvenated appearance and experience the empowerment of beautifully restored earlobes.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='grafting'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Facial Fat Grafting</h5>
                                    <p>Elevate your facial contours with Facial Fat Grafting, an innovative procedure artistically executed by our accomplished Maxillofacial Aesthetic Surgeon. This technique involves harvesting excess fat from one area of your body and carefully injecting it into areas of your face that lack volume or have lost their youthful fullness. Our surgeon's expertise ensures a personalized approach, tailoring the Facial Fat Grafting to your unique facial anatomy and desired outcomes. Through meticulous techniques, we offer a transformative solution that adds natural-looking volume, rejuvenating your appearance and enhancing your features. Trust in the hands of our experienced Maxillofacial Aesthetic Surgeon to guide you on a journey to renewed confidence, where Facial Fat Grafting helps you achieve a more harmonious and youthful facial balance. Embrace the potential of a sculpted transformation and experience the empowerment of naturally enhanced beauty.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service19} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service39} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='otoplasty'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Otoplasty, Transform your ear appearance.</h5>
                                    <p>Discover the confidence-boosting benefits of otoplasty, a specialized procedure offered by
                                        our skilled maxillofacial aesthetic surgeons. If you&#39;re unhappy with the shape, position, or
                                        size of your ears due to congenital issues, protrusion, or trauma, otoplasty can be the solution.
                                        Our surgeons meticulously sculpt and reshape ear cartilage, creating a natural, harmonious
                                        look that complements your overall facial aesthetics. Whether you&#39;re an adult seeking a
                                        refined appearance, or a parent concerned about a child&#39;s self-esteem, otoplasty can make a
                                        significant difference. Otoplasty is a minimally invasive procedure, often performed under
                                        local anaesthesia, with a relatively short recovery period. You can expect some initial
                                        swelling and bruising, which will diminish over a few weeks, revealing your new, balanced
                                        ear appearance. Boost your self-confidence and achieve the ear symmetry you desire with our
                                        otoplasty expertise. Contact us today to schedule a consultation and embark on your journey
                                        to ear aesthetics and enhanced self-esteem.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='cheeks'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Cheek Fat Removed: Enhance Your Facial Contours</h5>
                                    <p>Cheek fat removal, also known as buccal fat reduction, is a sought-after procedure for those
                                        looking to achieve more refined facial contours and a slimmer appearance. Excess buccal fat
                                        pads can create a round or chubby appearance in the lower cheeks, which may not align with
                                        your desired aesthetic. Our experienced maxillofacial aesthetic surgeons offer this specialized
                                        procedure to address these concerns. During the procedure, a small incision is made inside
                                        the mouth to access and carefully remove excess fat from the cheeks. This process sculpts the
                                        cheek area, revealing a more defined, contoured, and youthful appearance. Cheek fat removal
                                        is a minimally invasive surgery, resulting in minimal downtime. Swelling and bruising are
                                        temporary and typically resolve within a few weeks, unveiling your newly enhanced facial
                                        features. If you&#39;re looking to achieve a slimmer and more sculpted facial appearance, our
                                        cheek fat removal procedure can help you achieve your aesthetic goals. Contact us today to
                                        schedule a consultation and learn how this procedure can enhance your facial harmony and
                                        confidence.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service40} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service42} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='laser'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Laser Resurfacing: Rediscover Your Skin’s Natural Glow</h5>
                                    <p>Experience a transformation in your skin&#39;s texture, tone, and overall vitality with our
                                        advanced laser resurfacing treatment. Laser resurfacing is a non-surgical cosmetic procedure
                                        that effectively targets and corrects a variety of skin concerns, including fine lines, wrinkles,

                                        age spots, acne scars, and uneven pigmentation. Our skilled maxillofacial aesthetic surgeons
                                        utilize state-of-the-art laser technology to gently remove damaged skin layers, stimulating the
                                        production of collagen for a smoother, more youthful complexion. This precise and
                                        controlled approach minimizes discomfort and downtime while maximizing results. Laser
                                        resurfacing can be customized to suit your unique skin type and concerns, ensuring a tailored
                                        solution for your aesthetic goals. Whether you seek to rejuvenate your face, neck, or hands,
                                        our expert team is dedicated to helping you achieve stunning, long-lasting results. Reveal a
                                        more radiant, youthful you with laser resurfacing. Contact us today to schedule a consultation
                                        and embark on your journey to refreshed, revitalized skin.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='microneeding'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Micro needling: Revitalize Your Skin Naturally</h5>
                                    <p>Microneedling is a non-invasive skin rejuvenation technique that can work wonders for various skin concerns, from acne scars to fine lines and uneven texture. <strong>Microneedling Treatment in Delhi</strong>  is an innovative procedure that involves the use of a device equipped with tiny needles that create minuscule, virtually painless punctures on the skin's surface. These micro-injuries stimulate the skin's natural healing response, prompting the production of collagen and elastin – the essential proteins responsible for maintaining the skin's firmness and suppleness.
                                        As the skin repairs itself, it becomes smoother, more even-toned, and noticeably revitalized. After a
                                        microneedling session, some temporary redness and mild swelling, akin to a mild sunburn,
                                        may occur, but these effects typically subside quickly. Over time, you&#39;ll notice improved skin
                                        texture and a reduction in the appearance of scars, wrinkles, and fine lines. Microneedling is a
                                        safe and effective way to achieve a fresher, more youthful complexion without the need for
                                        surgery. If you&#39;re ready to naturally revitalize your skin, contact us today to schedule a
                                        microneedling session and begin your journey to healthier, radiant skin.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service43} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service44} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='pigmentation'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Pigmentation Correction: Reveal Your Radiant Skin</h5>
                                    <p>Pigmentation issues, such as dark spots, uneven skin tone, and melasma, can significantly impact your self-esteem and how you feel about your complexion. As a Best <strong> Skin Pigmentation Treatment Clinic in Delhi</strong>, we understand these concerns and offer a range of effective pigmentation correction treatments to address them.Our experienced team uses advanced techniques and state-of-the-art technologies to target pigmentation issues at their source, whether they're caused by sun exposure, aging, or hormonal fluctuations. We tailor our solutions to your unique needs,working to break down excess pigment and encourage its natural elimination, ultimately
                                        unveiling a clearer, brighter, and more even-toned complexion. Throughout the process, your
                                        comfort and safety are our top priorities, ensuring minimal discomfort and downtime. Say
                                        goodbye to pigmentation woes and hello to the radiant, flawless skin you deserve by
                                        scheduling a consultation with us today.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='rejuvention'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Skin Rejuvenation: Awaken Your Skin’s Natural Beauty</h5>
                                    <p>Skin rejuvenation is the key to unlocking your skin's natural beauty and vitality. Over time, factors like sun exposure, age, and lifestyle can take a toll on your skin, leaving it with imperfections like wrinkles, fine lines, sunspots, and uneven texture. Our <strong>Skin Rejuvenation Treatment in Delhi</strong>  is designed to turn back the clock and revitalize your skin. We offer a range of advanced techniques, including laser therapy, chemical peels, and microneedling, all tailored to your specific needs. These procedures stimulate collagen production, promoting tighter, smoother, and more youthful skin.  Our team of skilled professionals prioritizes your comfort  and safety throughout the process, ensuring minimal downtime and discomfort. Whether you  want to reduce the signs of ageing or improve skin tone and texture, our skin rejuvenation services can help you achieve your goals. Say hello to a more radiant, confident you. Contact us today to schedule a consultation and embark on your journey to rejuvenated, youthful skin.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service45} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service46} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='tattoo'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Tattoo Removal: Erase the Past, Renew Your Canvas</h5>
                                    <p>Tattoos are a form of self-expression, but sometimes, they no longer reflect who you are or
                                        what you want to convey. Whether it&#39;s an outdated design, a change in personal taste, or a
                                        desire for a clean slate, our tattoo removal service can help you start anew. Our advanced
                                        laser tattoo removal techniques are designed to safely and effectively eliminate unwanted
                                        tattoos. The laser breaks down the tattoo ink into tiny particles that your body can naturally
                                        flush away. Over several sessions, you&#39;ll see your tattoo gradually fade, revealing clearer skin
                                        beneath. Our experienced professionals tailor the treatment to your specific tattoo, ensuring
                                        minimal discomfort and minimal risk of scarring. The number of sessions required depends
                                        on factors like tattoo size, ink colour, and depth. If you&#39;re ready to erase the past and renew
                                        your canvas, contact us today for a consultation. Say goodbye to what no longer suits you and
                                        hello to a fresh beginning with our tattoo removal services.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='thread'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Thread Lift: Elevate Your Natural Beauty</h5>
                                    <p>A Thread Lift is a non-surgical, minimally invasive facelift alternative that can help you
                                        achieve a more youthful appearance without the need for extensive surgery or downtime. It&#39;s
                                        like a subtle, yet effective, lift for your face. During the procedure, fine threads are
                                        strategically inserted beneath your skin, gently lifting and repositioning sagging facial tissues.
                                        These threads are specially designed to stimulate collagen production, which means your
                                        results continue to improve over time. The treatment targets common signs of ageing like
                                        sagging cheeks, jowls, and a less defined jawline. The best part? It&#39;s a quick procedure with
                                        minimal discomfort and a short recovery period, making it a convenient choice for those with
                                        busy lives. Experience a natural-looking, rejuvenated version of yourself with our Thread
                                        Lift. Contact us today for a consultation and discover how you can elevate your natural
                                        beauty and regain your confidence.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service48} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service49} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='liquid'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Liquid Facelift: A Non-Surgical Rejuvenation</h5>
                                    <p>The Liquid Facelift is a cutting-edge non-surgical procedure that can take years off your
                                        appearance without the need for invasive surgery. Think of it as a rejuvenation cocktail for
                                        your face. During the treatment, a combination of dermal fillers and muscle relaxants is
                                        skilfully injected to address various signs of ageing. Dermal fillers restore lost volume,
                                        smoothing wrinkles and adding youthful contours, while muscle relaxants soften dynamic
                                        wrinkles, like crow&#39;s feet and forehead lines. This procedure is highly customizable and
                                        tailored to your unique facial features and goals. It provides a natural-looking enhancement,
                                        with results that can last several months to over a year. The Liquid Facelift targets sagging
                                        skin, fine lines, and volume loss, giving you a more refreshed and youthful appearance. With
                                        minimal discomfort and downtime, it&#39;s a convenient option for those seeking a non-surgical

                                        facelift. Experience a revitalized version of yourself with our Liquid Facelift. Contact us
                                        today for a consultation and discover how you can turn back the clock on ageing gracefully.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='bioprp'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Bio PRP Therapy: Harnessing Your Body’s Healing Power</h5>
                                    <p>Bio Platelet-Rich Plasma (PRP) Therapy is an innovative regenerative treatment that taps into
                                        your body&#39;s natural healing abilities to rejuvenate and revitalize your skin. Think of it as a
                                        biological boost for your skin&#39;s health and appearance. During this procedure, a small amount
                                        of your blood is drawn, and its plasma, rich in growth factors and platelets, is extracted. This
                                        golden elixir is then carefully injected into areas of concern, such as your face or scalp. PRP
                                        stimulates collagen production, improves skin texture, and promotes hair growth. Bio PRP
                                        Therapy is highly versatile and can address a range of issues, from fine lines and wrinkles to
                                        hair loss. It&#39;s a safe and minimally invasive procedure, with minimal discomfort and
                                        downtime. Discover the power of your body&#39;s own healing mechanisms with Bio PRP
                                        Therapy. Contact us today for a consultation and experience the natural transformation of
                                        your skin and hair.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service50} alt="" />
                            </div>
                        </Col>
                    </Row>

                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service47} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='carbon'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Carbon Laser Peel: Reveal Your Skin’s Inner Glow</h5>
                                    <p>The Carbon Laser Peel, often referred to as the "Hollywood Peel" or "Black Doll Facial," is a cutting-edge skincare treatment that delivers remarkable results in achieving smoother, more vibrant skin. As one of the Best Carbon Laser Peel Treatment in Delhi, this non-invasive procedure is a favorite among celebrities and skincare enthusiasts alike for its ability to rejuvenate the complexion. During the treatment, a layer of liquid carbon is applied to the skin's surface, where it penetrates deep into the pores. 
                                    Then, a  specialized laser is used to gently heat and vaporize the carbon, taking away impurities, dead skin cells, and excess oil. This process not only deeply cleanses the skin but also stimulates collagen production, promoting a more youthful and radiant appearance. Carbon Laser Peels are effective in reducing fine lines, wrinkles, and acne scars, and improving overall skin  texture. There is little to no downtime, making it suitable for busy lifestyles. Experience the transformative power of the Carbon Laser Peel and rediscover your skin&#39;s natural glow.
                                    Contact us today to schedule your consultation and take the first step towards radiant skin.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                    <Col md={6}>
                            <div className="service_name" id='botox'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Smooth Away Wrinkles with Advanced Botox Therapy</h5>
                                    <p>Experience the best Botox treatment in Delhi at Sharva Clinique, where expert dermatologists deliver safe and effective results.Our cutting-edge clinic provides tailored care, resulting in least discomfort and maximum happiness. Sharva Clinique offers Botox to smooth wrinkles, fine lines, and crow's feet, giving you a more young, revitalized appearance. Trust our expert professionals to provide you with natural-looking results in a short, non-invasive treatment. With a reputation for expertise, Sharva Clinique is Delhi's best pick for Botox treatments, dedicated to boosting your confidence and beauty.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service51} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Whatsapp />
        </>
    );
}

export default Services;