import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Otherheader from '../Header/Otherheader';
import { Container, Row, Col } from 'react-bootstrap';
import { LiaGreaterThanSolid } from "react-icons/lia";
import service22 from '../../assets/service22.png';
import service23 from '../../assets/service23.png';
import service24 from '../../assets/service24.png';
import service25 from '../../assets/service25.png';
import service26 from '../../assets/service26.png';
import service27 from '../../assets/service27.png';
import service28 from '../../assets/service28.png';
import service29 from '../../assets/service29.png';
import service30 from '../../assets/service30.png';
import service31 from '../../assets/service31.png';
import service32 from '../../assets/service32.png';
import service33 from '../../assets/service33.png';
import service34 from '../../assets/service34.png';
import service35 from '../../assets/service35.png';
import service36 from '../../assets/service36.png';
import service37 from '../../assets/service37.png';
import service38 from '../../assets/service38.png';
import './Services.css';
import Whatsapp from '../Whatsapp/Whatsapp';

const Gservices = () => {
    const { section } = useParams();

    useEffect(() => {
        const targetSection = document.getElementById(section);
        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, [section]);

    return (
        <>
            <Otherheader />
            <div className="service_banner">
                <Container>
                    <div className="appoint_left">
                        <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Services </p>
                    </div>

                    <div className="gallery_head text-white">
                        <h1 className='pt-5'>Services at Sharva <br /> Clinique </h1>
                        <p className='pt-2'>Explore our range of expert services, each tailored to your <br /> unique needs and delivered by our skilled team of professionals <br />  at Sharva Clinique. Your journey towards enhanced health and beauty starts here.</p>
                    </div>
                </Container>
            </div>

            <div className="service_content my-5">
                <div className="service_head text-center mb-5">
                    <h2>Our <span>Services</span></h2>
                </div>

                <Container>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service22} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='asthma'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Asthma </h5>
                                    <p>Experience comprehensive asthma care at the <strong>top asthma clinic in Delhi </strong> under the guidance of our expert Pulmonologist. With precision and empathy, we provide tailored treatments to manage and alleviate asthma symptoms. Our Pulmonologist's advanced knowledge ensures accurate diagnosis, personalized medication plans, and lifestyle guidance to enhance your quality of life. Trust in the hands of our experienced specialist to help you breathe freely and confidently. Through expert care and cutting-edge therapies, we are dedicated to empowering you in your journey toward effective asthma management and improved respiratory health. Breathe easier and embrace life fully with our specialized treatment of asthma.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='copd'>
                                <div className="service_desc">
                                    <h5 className='py-3'>COPD (Chronic Obstructive Pulmonary Disease) </h5>
                                    <p>Receive specialized care for Chronic Obstructive Pulmonary Disease (COPD) from our dedicated Pulmonologist. With expertise and compassion, we offer comprehensive treatments to manage COPD symptoms and enhance your respiratory well-being. Our Pulmonologist's precise diagnosis and personalized treatment plans ensure optimal management of this chronic condition. Through advanced therapies, lifestyle guidance, and continuous monitoring, we strive to improve your lung function and overall quality of life. Trust in the expertise of our experienced specialist to guide you towards better respiratory health and a more active, fulfilling lifestyle. Regain control over your breathing and embrace a life enriched by our expert COPD treatment.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service23} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service24} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='allergies'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Allergies  </h5>
                                    <p>Experience relief from allergies at the <strong>best allergy specialist in Delhi. </strong> With a focus on precise diagnosis and tailored treatments, our expert allergist offers solutions to alleviate your allergic reactions. From seasonal allergies to food sensitivities, our comprehensive approach includes personalized allergy testing, targeted medication plans, and lifestyle adjustments at our <strong>allergy test center</strong> . Trust in the hands of our experienced specialist to help you overcome allergic challenges and enjoy life to the fullest. Through cutting-edge therapies and expert guidance, we are committed to enhancing your well-being and empowering you to effectively manage your allergies. Embrace a life free from the constraints of allergies with our expert allergy treatment.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='tb'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Tuberculosis (TB)</h5>
                                    <p>Receive comprehensive care for Tuberculosis (TB) from our experienced medical team. Our dedicated experts ensure accurate diagnosis, personalized treatment plans, and ongoing monitoring to effectively manage TB. With a focus on antibiotic therapy and patient support, we guide you through the entire treatment process, ensuring adherence and minimizing potential side effects. Trust in our expertise to help you overcome TB and regain your health. Through advanced therapies and compassionate care, we are committed to your well-being and the eradication of this infectious disease. Embrace a journey towards recovery and a brighter, healthier future with our expert TB treatment.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service25} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service26} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='cough'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Cough and Cold Treatment</h5>
                                    <p>Experience relief from cough and cold symptoms through expert care provided by our skilled medical professionals. Our dedicated team offers personalized treatment plans to alleviate discomfort and promote recovery. From over-the-counter remedies to prescription medications, we tailor interventions to address your specific needs. Trust in our expertise to guide you toward relief, whether it's a persistent cough, congestion, or other cold-related symptoms. With a focus on your well-being, we provide guidance on self-care practices and offer medical support to help you regain your health. Embrace comfort and a speedy recovery with our specialized cough and cold treatment.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='chest'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Chest Infection</h5>
                                    <p>Receive prompt and effective care for <strong>chest infection treatment in Delhi </strong> from our experienced medical team. We specialize in diagnosing and treating a wide range of chest infections, including bronchitis, pneumonia, and other respiratory conditions Our dedicated experts utilize advanced diagnostic techniques to accurately identify the cause of your infection and develop a personalized treatment plan. From antibiotics and respiratory therapies to symptom management, we prioritize your comfort and recovery. Trust in our expertise to guide you through the healing process, ensuring thorough monitoring and timely adjustments to your treatment. Embrace a healthier respiratory future with our specialized chest infection treatment, tailored to your individual needs.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service27} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service28} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='snoring'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Snoring</h5>
                                    <p>Discover effective solutions for snoring through our specialized care. Our experienced medical professionals offer comprehensive assessment and tailored treatment plans to address the underlying causes of snoring. From lifestyle adjustments and positional therapy to oral appliances and, if necessary, surgical options, we are committed to helping you and your loved ones achieve restful sleep. Trust in our expertise to guide you towards quieter nights and improved sleep quality. With a focus on your well-being, we provide personalized guidance and support to alleviate snoring and its potential impact on your health. Embrace a more peaceful sleep and a revitalized sense of energy with our expert snoring treatment.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='sleep'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Sleep Disturbances</h5>
                                    <p>Receive prompt and effective care for chest infections from our experienced medical team. We specialize in diagnosing and treating a wide range of chest infections, including bronchitis, pneumonia, and other respiratory conditions. Our dedicated experts utilize advanced diagnostic techniques to accurately identify the cause of your infection and develop a personalized treatment plan. From antibiotics and respiratory therapies to symptom management, we prioritize your comfort and recovery. Trust in our expertise to guide you through the healing process, ensuring thorough monitoring and timely adjustments to your treatment. Embrace a healthier respiratory future with our specialized chest infection treatment, tailored to your individual needs.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service29} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service30} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='respiratory'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Respiratory Issues</h5>
                                    <p>Experience comprehensive care for respiratory issues from our specialized medical team. We are dedicated to diagnosing and managing a wide range of respiratory conditions, including asthma, chronic obstructive pulmonary disease (COPD), pneumonia, bronchitis, and more. Our experts utilize advanced diagnostic tools to accurately assess your lung health and develop personalized treatment plans. From inhalers and medications to pulmonary rehabilitation and lifestyle adjustments, we prioritize your respiratory well-being. Trust in our expertise to guide you towards improved lung function and a higher quality of life. With a focus on your health and comfort, we provide ongoing support and monitoring to help you breathe easier and enjoy better respiratory health. Embrace the potential for enhanced lung function and vitality with our expert treatment of respiratory issues.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='sinusitis'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Sinusitis</h5>
                                    <p>Experience effective relief from sinusitis with our specialized care. Our experienced medical team is dedicated to diagnosing and treating sinus infections and inflammation. We offer personalized treatment plans that may include antibiotics, decongestants, nasal sprays, and pain relievers to alleviate symptoms and promote healing. Trust in our expertise to guide you through the recovery process, providing thorough monitoring and adjustments as needed. With a focus on your comfort and well-being, we strive to help you breathe easier and enjoy a healthier quality of life. Embrace the potential for sinus relief and improved sinus health with our expert sinusitis treatment.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service31} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service32} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='eosinophilia'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Eosinophilia </h5>
                                    <p>Receive specialized care for eosinophilia from our dedicated medical team. Eosinophilia is a condition characterized by elevated levels of eosinophils, a type of white blood cell, which can be caused by various underlying factors such as allergies, infections, autoimmune disorders, or other medical conditions. Our experts are skilled in diagnosing the cause of eosinophilia and developing tailored treatment plans to address the root cause and manage symptoms. Trust in our expertise to guide you towards effective management of eosinophilia, providing thorough monitoring and necessary interventions. With a focus on your health and well-being, we strive to help you achieve optimal blood cell balance and overall vitality. Embrace the potential for improved health and well-being with our expert eosinophilia care.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='diabetes'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Diabetes</h5>
                                    <p>Experience comprehensive care for diabetes from our specialized medical team. We are dedicated to managing all aspects of diabetes, including type 1, type 2, and gestational diabetes. Our experts offer personalized treatment plans that may include blood sugar monitoring, oral medications, insulin therapy, lifestyle modifications, and nutritional guidance. Trust in our expertise to guide you towards better blood sugar control and improved overall health. With a focus on your well-being, we provide ongoing support, education, and monitoring to help you effectively manage diabetes and reduce the risk of complications. Embrace the potential for better glucose control and enhanced quality of life with our expert diabetes treatment.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service33} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service34} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='neuropathy'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Diabetic Neuropathy</h5>
                                    <p>Receive specialized care for diabetic neuropathy from our dedicated medical team. Diabetic neuropathy is a condition that affects the nerves, often causing numbness, tingling, pain, or weakness in the extremities. Our experts are skilled in diagnosing and managing diabetic neuropathy, offering tailored treatment plans to alleviate symptoms and prevent progression. Trust in our expertise to guide you toward effective management, which may include medications to control pain and discomfort, lifestyle adjustments, and methods to improve blood sugar control. With a focus on your well-being, we provide ongoing support and monitoring to help you achieve relief and maintain optimal nerve health. Embrace the potential for improved nerve function and enhanced quality of life with our expert diabetic neuropathy care.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='hypertension'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Hypertension </h5>
                                    <p>Receive specialized care for hypertension (high blood pressure) from our dedicated medical team. Hypertension is a common condition that can increase the risk of heart disease, stroke, and other health complications. Our experts are skilled in diagnosing and managing hypertension, offering personalized treatment plans that may include lifestyle modifications, dietary changes, exercise recommendations, and medications when necessary. Trust in our expertise to guide you towards better blood pressure control and improved cardiovascular health. With a focus on your well-being, we provide ongoing support, education, and monitoring to help you effectively manage hypertension and reduce associated risks. Embrace the potential for lower blood pressure and enhanced overall health with our expert hypertension care.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service35} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service36} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='ecg'>
                                <div className="service_desc">
                                    <h5 className='py-3'>ECG (Electrocardiogram) </h5>
                                    <p>Experience advanced heart health assessment with our Electrocardiogram (ECG) service. Using state-of-the-art technology, we record your heart's electrical activity to detect irregularities, such as arrhythmias or heart conditions. Our skilled medical team ensures accurate results, aiding in early diagnosis and effective management of cardiac health. Trust in our expertise to provide a comprehensive ECG analysis, contributing to your overall well-being and heart health. Embrace the potential for early detection and timely intervention with our expert ECG service.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_name" id='pft'>
                                <div className="service_desc">
                                    <h5 className='py-3'>PFT (Pulmonary Function Test)</h5>
                                    <p>Elevate your respiratory health with our Pulmonary Function Test (PFT) service. Using advanced equipment, we measure your lung capacity, airflow, and overall lung function. Our dedicated medical team ensures precise assessments, helping diagnose conditions like asthma, COPD, and lung diseases. Trust in our expertise to provide comprehensive PFT analysis, contributing to better respiratory well-being and lung health. Embrace the potential for early detection and personalized treatment with our expert PFT service.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service37} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col md={6}>
                            <div className="service_img mt-3">
                                <img src={service38} alt="" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="service_name" id='sugar'>
                                <div className="service_desc">
                                    <h5 className='py-3'>Sugar Profile</h5>
                                    <p>Prioritize your metabolic health with our Sugar Profile service. This comprehensive assessment measures blood glucose levels, aiding in the early detection and management of diabetes. Our experienced medical team ensures accurate readings, enabling timely interventions and lifestyle modifications. Trust in our expertise to provide thorough sugar profile analysis, contributing to improved blood sugar control and overall well-being. Embrace the potential for early intervention and informed choices for a healthier future with our expert Sugar Profile service.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Whatsapp/>
        </>
    );
}

export default Gservices;