import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import doc_abt_img from '../../../assets/doc_about.png'
import doc_abt_img1 from '../../../assets/doc_about1.png'
import doc_abt_img2 from '../../../assets/doc_about2.png'

const Doctorabout = () => {
    return (
        <>
            <div className="doctor_about my-5">
                <Container>
                    <div className='Button_healthy'>
                        <span className='lets'>Our Team</span>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className="doc_head my-4">
                                <h1>Meet Our Expert <span>Physicians</span></h1>
                            </div>
                            <div className="doc_card">
                                <Row>
                                    <Col md={6}>
                                        <div className="doc_card_left">
                                            <p className='speciality'>Maxillofacial Aesthetic Surgeon</p>
                                            <h5>Dr. Shweta Mishra</h5>
                                            <p className='abt_p'>Dr. Shweta mishra is a Maxillofacial Aesthetic Surgeon, super-specialized in Facial Cosmetics, and Hair Transplantation</p>

                                            <div className="doc_social">
                                                <FaFacebookF className='doc_social_icon' />
                                                <FaTwitter className='doc_social_icon' />
                                                <FaLinkedinIn className='doc_social_icon' />
                                                <FaInstagram className='doc_social_icon' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="doc_abt_img">
                                            <img src={doc_abt_img} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="doc_card my-5">
                                <Row>
                                    <Col md={6}>
                                        <div className="doc_card_left">
                                            <p className='speciality'>Pulmonologist</p>
                                            <h5>Dr. Gaurav Pandey</h5>
                                            <p className='abt_p'>Dr Gaurav Pandey is working at a pvt. multispeciality clinic as a senior MD pulmonologist.</p>

                                            <div className="doc_social">
                                                <FaFacebookF className='doc_social_icon' />
                                                <FaTwitter className='doc_social_icon' />
                                                <FaLinkedinIn className='doc_social_icon' />
                                                <FaInstagram className='doc_social_icon' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="doc_abt_img">
                                            <img src={doc_abt_img1} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="doc_card my-5">
                                <Row>
                                    <Col md={6}>
                                        <div className="doc_card_left">
                                            <p className='speciality'>Aesthetic Physician</p>
                                            <h5>Dr. Shubham Pandey</h5>
                                            <p className='abt_p'>General physician with special interest in Aesthetic medicine.</p>

                                            <div className="doc_social">
                                                <FaFacebookF className='doc_social_icon' />
                                                <FaTwitter className='doc_social_icon' />
                                                <FaLinkedinIn className='doc_social_icon' />
                                                <FaInstagram className='doc_social_icon' />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="doc_abt_img">
                                            <img src={doc_abt_img2} alt="" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Doctorabout;
