import React from 'react';
import Otherheader from '../Header/Otherheader'
import Gallerybanner from './gallery_banner/Gallerybanner';
import './gallery.css';
import Gallerytabs from './gallery_tabs/Gallerytabs';
import Afterbefore from './after_before/Afterbefore';
import Whatsapp from '../Whatsapp/Whatsapp';

const Gallery = () => {
    return (
        <>
            <Otherheader />
            <Gallerybanner />
            <Gallerytabs />
            <Afterbefore />
            <Whatsapp />
        </>
    );
}

export default Gallery;
