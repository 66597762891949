import React from 'react';
import './contact.css';
import Otherheader from  '../Header/Otherheader';
import Contactbanner from './ContactBanner/Contactbanner';
import Contactdata from './Contact_data/Contactdata';
import Whatsapp from '../Whatsapp/Whatsapp';

const Contact = () => {
    return (
        <>
            <Otherheader/>
            <Contactbanner/>
            <Contactdata/>
            <Whatsapp/>
        </>
    );
}

export default Contact;
